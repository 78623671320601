



































































import {Vue, Component, Watch} from "vue-property-decorator";
import {DistributionSet} from "@/request/marketing/DistributionSet";

@Component({
    name: 'marketing_distributionSet'
})
export default class marketing_distributionSet extends Vue {
    modal: any = DistributionSet;
    depositFeeMin: number = 1;
    drawType: number = 0;
    depositFeeMinBtnLoading: boolean = true;
    distributionFirstRateMax: number = 99;
    distributionSecondRateMax: number = 99;
    formData: any = {
        name: '',
        distributionFirstRate: 1,
        distributionSecondRate: 1
    };
    formRules: any = {
        name: {required: true, trigger: 'blur'},
        distributionFirstRate: {required: true, type: 'number', trigger: 'blur'},
        distributionSecondRate: {required: true, type: 'number', trigger: 'blur'},
    };
    columns: Array<any> = [
        {
            title: '名称',
            key: 'name',
            minWidth: 140
        },
        {
            title: '一级分佣比例',
            key: 'distributionFirstRate',
            minWidth: 120,
            render: (h: any, p: any) => h('p', `${p.row.distributionFirstRate}%`)
        },
        {
            title: '二级分拥比例',
            key: 'distributionSecondRate',
            minWidth: 120,
            render: (h: any, p: any) => h('p', `${p.row.distributionSecondRate}%`)
        },
        {
            title: '创建时间',
            key: 'createTime',
            minWidth: 160
        }
    ];
    created() {
        DistributionSet.getDeposit().then((body: any) => {
            this.depositFeeMinBtnLoading = false;
            if (body.code === 0) {
                this.depositFeeMin = Number(this.fen2yuan(body.data))
            }
        });
        DistributionSet.getDrawType().then((body: any) => {
            if (body.code === 0) {
                this.drawType = body.data;
            }
        })
    }
    @Watch('formData.distributionFirstRate')
    distributionFirstRateChange(newValue: number) {
        this.distributionSecondRateMax = 100 - newValue;
    }
    @Watch('formData.distributionSecondRate')
    distributionSecondRateChange(newValue: number) {
        this.distributionFirstRateMax = 100 - newValue;
    }
    createNew() {
        delete this.formData.id;
    }
    edit(row: any) {
        this.formData = row;
    }
    submit() {
        let self: any = this;
        let params = {...self.formData};
        self.$refs['el-table'].doSubmitWithValidate(params).then(() => {

        });
    }
    setDepositFee() {
        let fee: number = this.depositFeeMin * 100;
        this.depositFeeMinBtnLoading = true;
        DistributionSet.setDeposit(fee).then((body: any) => {
            this.depositFeeMinBtnLoading = false;
            if (body.code === 0) {
                this.$Message.info('设置成功');
            }
        })
    }
    changeDrawType(value: any) {
        console.log(value);
        this.$Message.loading('请稍后...');
        DistributionSet.setDrawType(value).then((body: any) => {
            if (body.code === 0) {
                this.$Message.destroy();
                this.$Message.info('修改成功');
            }
        })
    }
}
