import {BaseRequest} from "@/request/BaseRequest";

class DistributionSet extends BaseRequest {

    public requestPath: string = '/marketing/distributionSet';

    /**
     * 获取
     */
    getDeposit(): any {
        return this.get(`${this.requestPath}/depositGet`);
    }

    /**
     * 设置提现金额
     * @param fee
     */
    setDeposit(fee: number): any {
        return this.post(`${this.requestPath}/depositSet`, {depositFeeMin: fee});
    }

    /**
     * 获取提现方式
     */
    getDrawType(): any {
        return this.get('/marketing/distributionSet/withdrawGet');
    }

    /**
     * 设置提现方式
     * @param type
     */
    setDrawType(type: number): any {
        return this.post('/marketing/distributionSet/withdrawSet', {withdrawType: type});
    }

}

const c = new DistributionSet();
export {c as DistributionSet};